import * as React from "react";
import {
    Container,
    Grid,
    GridItem,
    Stack,
    Text,
  } from "@chakra-ui/react";
import siteConfig from "configs/site-config";

var scores = [
    ['Katie', 32.02, '04/29/2024'],
    ['Daniel', 32.84, '05/08/2024'],
    ['Ben', 33.77, '05/08/2024'],
    ['Daniel', 34.11, '04/29/2024'],
    ['Ben', 35.03, '05/07/2024'],
    ['Sam', 39.42, '05/07/2024'],
    ['Katie & Ben Tandem', '1:14.83', '05/09/2024'],
];

const FreeSkateScores = () => {
  return (
    <Container  maxWidth='90%' >
        
        {/* Actual content here */}
        <Stack spacing={10} textAlign={'left'} >

            <Text fontSize='4xl'>Free Skates 100m challenge (backward-turning)</Text>
            {/* Table of scores and date */}
            <Grid templateColumns="repeat(3, 1fr)" gap={6} >
                <GridItem as='b' colSpan={1} >
                    <Text>Skater</Text>
                </GridItem>
                <GridItem as='b' colSpan={1} >
                    <Text>Time (s)</Text>
                </GridItem>
                <GridItem as='b' colSpan={1} >
                    <Text>Date</Text>
                </GridItem>
            </Grid>
            <Grid templateColumns="repeat(3, 1fr)" gap={6} >
                {scores.map((score) => (
                    <>
                    <GridItem colSpan={1} >
                        <Text>{score[0]}</Text>
                    </GridItem>
                    <GridItem colSpan={1} >
                        <Text>{score[1]}</Text>
                    </GridItem>
                    <GridItem colSpan={1} >
                        <Text>{score[2]}</Text>
                    </GridItem>
                    </>
                ))}
            </Grid>

        </Stack>
    </Container>
  )
}

export default FreeSkateScores;
