import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "style/theme";
import Layout from "./layout/layout";

export const App = () => (
  <Router>
    <ChakraProvider theme={theme} resetCSS={true}>
      <Layout />
    </ChakraProvider>
  </Router>
);
