import * as React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Flex,
    Stack,
    IconButton,
    Image,
    Link,
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
    Text,
    VStack
  } from "@chakra-ui/react";
import {
  BsArrowReturnRight
} from "react-icons/bs";
import siteConfig from "configs/site-config";

const Experience = () => {
  return (
    <Accordion defaultIndex={[0]} allowMultiple allowToggle>
      {siteConfig.experience.map((job, job_id) => 
        <AccordionItem key={"accordion-" + job_id.toString()}>
          <AccordionButton>
            <Box flex='1' textAlign='right'>
              <Image src={job.img} maxHeight="100"/>
            </Box>
            ({job.years})
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box textAlign='left'>
              <VStack>
              {/* <p> */}
              <Text fontSize='3xl'>
                {job.title}
                </Text>
              {/* </p> */}
              <List spacing={3}>
                {job.sections.map((section, sec_id) =>
                  <h5 key={"acc-sec-" + sec_id.toString()}>
                  <ListItem>
                    <ListIcon as={BsArrowReturnRight} color='blue.500' />  
                      {section}
                  </ListItem>
                  </h5>
                )}


              </List>
              </VStack>
            </Box>
          </AccordionPanel>
        </AccordionItem>
    )}
    </Accordion>
  )
}

export default Experience;
