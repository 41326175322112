import React from "react";
import {
  FaGithub,
  FaLinkedin,
  FaTwitter,
  FaSpotify,
  FaInstagram,
  FaLink,
  FaSoundcloud,
  FaMusic,
  FaYoutube,
  FaGraduationCap,
  FaMastodon,
} from "react-icons/fa";
import {
  Text
} from "@chakra-ui/react";

import NYUImg from "assets/images/experience/nyu.jpg";
import StanfordImg from "assets/images/experience/stanford.png";
import TwImg from "assets/images/experience/twitter.png";
import NKIImg from "assets/images/experience/nki.png";
import BraidImg from "assets/images/experience/braid.png";

const siteConfig = {
  author: {
    name: "Daniel Hasegan",
    accounts: [
      {
        url: "https://twitter.com/dhasemusic",
        label: "Twitter Account",
        type: "twitter",
        icon: <FaTwitter />
      },
      {
        url: "https://github.com/dhasegan",
        label: "Github Account",
        type: "gray",
        icon: <FaGithub />
      },
      {
        url: "https://linkedin.com/in/dhasegan",
        label: "LinkedIn Account",
        type: "linkedin",
        icon: <FaLinkedin />
      },
      {
        url: "https://scholar.google.com/citations?hl=en&user=wJd-E18AAAAJ",
        label: "Google scholar",
        type: "blue",
        icon: <FaGraduationCap />
      },
      {
        url: "https://sigmoid.social/@daniel",
        label: "Mastodon Account",
        type: "gray",
        icon: <FaMastodon />
      }
    ]
  },
  publications: {
    'preprints': [
    ],
    'pubs': [
      {
        'year': 2024,
        'text': (<><Text as='b'>Haşegan, D.</Text>, Geniesse, C., Chowdhury, S. and Saggar, M., 2023.
                Deconstructing the Mapper algorithm to extract richer topological and temporal features
                from functional neuroimaging data. <Text as='i'>Network Neuroscience</Text>, pp.1-60.
                doi: <a href='https://doi.org/10.1162/netn_a_00403'>https://doi.org/10.1162/netn_a_00403</a></>)
      },
      {
        'year': 2023,
        'text': (<>Auernhammer, J., Bruno, J., Booras, A., McIntyre, C., <Text as='b'>Hasegan, D.</Text>,
                and Saggar, M., 2023. NeuroDesign: Greater than the Sum of Its Parts.
                In Design Thinking Research: Innovation–Insight–Then and Now (pp. 197-211).
                Cham: Springer Nature Switzerland.
                doi: <a href='https://doi.org/10.1007/978-3-031-36103-6_10'>https://doi.org/10.1007/978-3-031-36103-6_10</a></>)
      },
      {
        'year': 2022,
        'text': (<><Text as='b'>Haşegan, D.</Text>, Deible, M., Earl, C., D'Onofrio, D., Hazan, H., Anwar, H. and
                Neymotin, S.A., 2022. Training spiking neuronal networks to perform motor control using
                reinforcement and evolutionary learning. Frontiers in computational neuroscience, 16, p.1017284. 
                doi: <a href='https://doi.org/10.3389/fncom.2022.1017284'>https://doi.org/10.3389/fncom.2022.1017284</a></>)
      },
      {
        'year': 2022,
        'text': (<>Anwar, H., Caby, S., Dura-Bernal, S., D'Onofrio, D., <Text as='b'>Hasegan, D.</Text>, Deible, M., Grunblatt, S., Chadderdon, G.L., Kerr, C.C., Lakatos, P. and Lytton, W.W., 2022.
                Training a spiking neuronal network model of visual-motor cortex to play a virtual racket-ball game using reinforcement learning. <Text as='i'>PloS one</Text>, 17(5), p.e0265808.
                doi: <a href='https://doi.org/10.1371/journal.pone.0265808'>https://doi.org/10.1371/journal.pone.0265808</a></>)
      }
    ],
    'posters': [
      {
        'year': 2022,
        'text': (<><Text as='b'>Hasegan, D.</Text>, Geniesse, C., Chowdhury S., Saggar M. Deconstructing Mapper to reveal the brain’s dynamic organization. Computational Psychiatry Course (CPC++)</>)
      },
      {
        'year': 2021,
        'text': (<><Text as='b'>Hasegan, D.</Text>, D’Onofrio, D., Grunblatt, S., Deible, M., Caby, S., Hazan, H., Anwar, H., S.A. Neymotin.
                Using Spiking Neuronal Networks to solve Reinforcement Learning control problems. Society for Neuroscience conference (SfN)</>)
      }
    ]
  },
  experience: [
    {
      img: NYUImg,
      title: (<>Graduate Student in the <a href='https://neuroscience.nyu.edu/program.html'>NYU Neuroscience program</a></>),
      sections: [
        "Pursuing specialization in the Systems, Cognitive, and Computational (SCC) track to delve into the multifaceted dimensions of neuroscience.",
        "Engaged in rigorous coursework and research to build a solid foundation in understanding brain dynamics and cognitive processes.",
        "Joined Dr. Biyu He's Lab as a Graduate Student"
      ],
      years: "2022 - present"
    },
    {
      img: StanfordImg,
      title: (<>Research Data Analyst II @ <a href='https://braindynamicslab.github.io/'>Brain Dynamics Lab</a></>),
      sections: [
        "Collaborated within Dr. Manish Saggar’s Lab to process and analyze extensive fMRI datasets, each comprising over 1000 subjects.",
        "Employed cutting-edge tools including Mapper from Topological Data Analysis (TDA) to gain insights into the intricate topology of brain patterns.",
        "Contributed as a Level 2 operator, performing MRI scans for research purposes.",
      ],
      years: "2021 - 2022"
    },
    {
      img: NKIImg,
      title: "Collaborator @ Nathan S. Kline Institute for Psychiatric Research, NY",
      sections: [
        "Investigated Spiking Neuronal Networks, utilizing biologically-plausible learning rules such as Spike-Timing Dependent Plasticity, Reinforcement Learning, and Evolutionary Strategies.",
        "Focused on solving control problems like CartPole from OpenAI Gym, collaborating closely with Dr. Samuel Neymotin and Dr. Haroon Anwar."
      ],
      years: "2021 - 2022"
    },
    {
      img: BraidImg,
      title: (<>Lead Data Software Engineer @ <a href='https://braid.health'> Braid Health, San Francisco </a></>),
      sections: [
        "Played a foundational role as the first employee after the company's inception, establishing critical infrastructure and steering Data and Machine Learning initiatives.",
        "Designed and operationalized advanced X-Ray disease detection models, enhancing diagnostic accuracy and efficiency for clinics and radiology departments.",
        "Spearheaded the creation of an automated data pipeline, unifying diverse data sources, facilitating model training, deployment, and testing. This pivotal pipeline accelerated exploration of model architecture and hyperparameters.",
        "Innovated with novel models for musculoskeletal radiographs across multiple view positions.",
        "To improve application and data integrity, I developed image preprocessing algorithms, migrated front-end models(WebDNN to TensorflowJS), optimized data structures, and increased the interpretability of Machine Learning results.",
        "I maintained Site Reliability by supporting customers, evaluating failure modes, and planning team objectives.",
      ],
      years: "2018 - 2021"
    },
    {
      img: TwImg,
      title: "Staff ML Software Engineer @ Twitter, San Francisco",
      sections: [
        "Contributed to the Content Understanding and Applied Deep Learning team, pioneering advancements in Named Entity Recognition (NER) models and their applications in Twitter Trends and Email Recommendations",
        "I championed the utilization of TensorFlow as a premier Machine Learning Framework, subsequently becoming the standard framework for ML at Twitter. After that project, I was actively involved in ML platform tooling.",
        "As part of the Twitter Cortex team, I developed short clip generation from Periscope video streams, branded as Periscope Replay Highlights. I also worked on servicing and training pipelines for multiple Machine Learning models for abusive content, image and video classifcation, and Not Safe For Work (and further Not Safe For Ads) tweets and users.",
        "I created a human-in-the-loop pipeline to evaluate the efficiency of ML models on a weekly basis. I supervised an intern in building an Active Learning pipeline that uses the weekly labeled results to update the trained model.",
        "In collaboration with the Periscope Team and AR/VR Director, I built a plugin in Unity that injects live feedback (comments and hearts) into a Virtual Reality environment for an immersive VR streaming experience.",
      ],
      years: "2014 - 2018"
    }
  ],
  music: {
    intremor: {
      links: [
        {
          url: "https://intentiontremor.com",
          label: "Website",
          type: "blue",
          icon: <FaLink />
        },
        {
          url: "https://open.spotify.com/artist/16sBzFXMytMPMcFLEGnQpA?si=I20eOV85Tp2RInsYHeOzEA",
          label: "Spotify account",
          type: "spotify",
          icon: <FaSpotify />
        },
        {
          url: "https://www.instagram.com/intentiontremor/",
          label: "Instagram account",
          type: "instagram",
          icon: <FaInstagram />
        },
        {
          url: "https://www.youtube.com/channel/UCSryEdo1pzVVgBmwEpZBHNA",
          label: "Youtube account",
          type: "red",
          icon: <FaYoutube />
        },
        {
          url: "https://music.apple.com/us/album/intentiontremor-ep/1481518298?at=1001lbRT&ct=333102",
          label: "Apple Music Link",
          type: "red",
          icon: <FaMusic />
        },
        {
          url: "https://soundcloud.com/intentiontremor",
          label: "Soundcloud Link",
          type: "orange",
          icon: <FaSoundcloud />
        },
      ]
    }
  }
};

export default siteConfig;
