import * as React from "react";
import {
    Box,
    Flex,
    Stack,
    IconButton,
    Image,
    Link,
    Text,
    VStack
  } from "@chakra-ui/react";

import ImgB1 from "assets/images/books/b1.jpg";
import ImgB2 from "assets/images/books/b2.jpg";
import ImgB3 from "assets/images/books/b3.jpg";
import ImgB4 from "assets/images/books/b4.jpg";
import ImgB5 from "assets/images/books/b5.jpg";
import ImgB6 from "assets/images/books/b6.jpg";
import ImgB7 from "assets/images/books/b7.jpg";
import ImgB8 from "assets/images/books/b8.jpg";
import ImgB9 from "assets/images/books/b9.jpg";
import ImgB10 from "assets/images/books/b10.jpg";

const Books = () => {
  return (
    <VStack spacing={24}>
        <Box borderWidth='1px' borderRadius='lg' p='6' color='white' bg='white'>
            <Text fontSize='xlg' color='black'>
                I'm selling most of my books to fund my graduate degree. Check out below.
                <br></br>
                Any Paperback = $5 and Any Hardcover $10.
                <br></br>
                It's first come, first serve, so message me in private to buy it.
            </Text>
        </Box>
        <Image src={ImgB1} alt='Books 1' />
        <Image src={ImgB2} alt='Books 2' />
        <Image src={ImgB3} alt='Books 3' />
        <Image src={ImgB4} alt='Books 4' />
        <Image src={ImgB5} alt='Books 5' />
        <Image src={ImgB6} alt='Books 6' />
        <Image src={ImgB7} alt='Books 7' />
        <Image src={ImgB8} alt='Books 8' />
        <Image src={ImgB9} alt='Books 9' />
        <Image src={ImgB10} alt='Books 10' />
    </VStack>

  );
};

export default Books;
