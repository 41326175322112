import * as React from "react";
import {
    Box,
    Flex,
    Stack,
    IconButton,
    Image,
    Link,
    Text,
    VStack
  } from "@chakra-ui/react";
import InTremorLogo from "assets/images/music/intremor-logo.png";
import InTremorShow from "assets/images/music/dnalounge.png";
import siteConfig from "configs/site-config";

const iconProps = {
    variant: "ghost",
    size: "lg",
    isRound: true
  };

const Music = () => {
  return (
    <VStack spacing='24px'>
        <Box borderWidth='1px' borderRadius='lg' p='5' color='white' bg='white'>
            <VStack spacing={3}>
                <Text fontSize={'6xl'} color='black'>Solo project</Text>

                <Text fontSize='lg' color='black'>
                    I produced a song here and there
                </Text>

                <Stack direction={'row'} spacing={3}>
                    <Box className="video-responsive">
                        <iframe
                            width="100%" height="300" scrolling="no" allow="autoplay"
                            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/260759491&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
                        </iframe>
                    </Box>

                    <Box className="video-responsive">
                        <iframe
                            width="100%" height="300" scrolling="no" allow="autoplay"
                            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/335493721&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
                        </iframe>
                    </Box>
                </Stack>
            </VStack>
        </Box>
        <Box borderWidth='1px' borderRadius='lg' p='6' color='white' bg='white'> </Box>
        <Flex>
        <Box borderWidth='1px' borderRadius='lg' p='6' color='white' bg='white'>
            <VStack spacing='12px'>
                <Image src={InTremorLogo} alt='Intention Tremor' />
                <Text fontSize='lg' color='black'>
                    We make music that we love. I play bass guitar and create the light show during our performances.
                    In terms of music genre, it's better to listen and make your own opinion as each musician has it's own influences.
                    We released an EP in 2019 and we played a ton of shows in and around the San Francisco Bay Area.
                    Listen to our released songs on any streaming platform or come to one of our shows!
                </Text>

                <Box textAlign="center">
                {siteConfig.music.intremor.links.map((sc, index) => (
                    <IconButton
                        key={index}
                        as={Link}
                        isExternal
                        href={sc.url}
                        aria-label={sc.label}
                        size="lg"
                        colorScheme={sc.type}
                        icon={sc.icon}
                        {...iconProps}
                    />
                ))}
                </Box>

                <Image
                    src={InTremorShow} 
                    borderRadius='3%'
                    alt='Show at DNA Lounge' />

                <Text fontSize='lg' color='black'>
                    I made a music video for our song Send it My Way! Check it out ☟
                </Text>

                <Box className="video-responsive">
                    <iframe
                        width="100%" height="200" scrolling="no"
                        src={`https://www.youtube.com/embed/ac9rkWkWWxo`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube: 'Send it my way' by intention tremor"
                    /> 
                </Box>
            </VStack>
        </Box>
        </Flex>


        <Box borderWidth='1px' borderRadius='lg' p='6' color='white' bg='white'></Box>


        <Box borderWidth='1px' borderRadius='lg' p='5' color='white' bg='white'>
            <VStack spacing={3}>
                <Text fontSize={'6xl'} color='black'>Sourgreen</Text>

                <Text fontSize='lg' color='black'>
                    For a few years, while in San Francisco, I played bass in the band Sourgreen. We recorded some tracks and played some shows.
                    Check below the Stork Club show in Oakland, that one was especially a lot of fun.
                </Text>

                <Stack direction={'row'} spacing={3}>
                    <Box className="video-responsive">
                    <iframe
                        width="100%" height="300" scrolling="no" allow="autoplay" 
                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/303441347&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
                    </iframe>
                    </Box>

                    <Box className="video-responsive">
                    <iframe
                        width="100%" height="300" scrolling="no" allow="autoplay"
                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/426441342&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                    </Box>

                </Stack>
            </VStack>
        </Box>

    </VStack>

  );
};

export default Music;
