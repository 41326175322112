import * as React from "react";
import {
  Box,
  Flex,
  Avatar,
  Stack,
  HStack,
  Link,
  IconButton,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { ColorModeSwitcher } from "ColorModeSwitcher";
import UserIcon from "assets/images/user_icon.png";

const webLinks = [
  { name: "Publications", path: "/publications" },
  { name: "Experience", path: "/experience" },
  { name: "Music", path: "/music" },
];

const mobileLinks = [
  { name: "Home", path: "/" },
  ...webLinks
];

interface NavLinkProps {
  index?: string;
  name: string;
  path: string;
  onClose: () => void;
  notRoute?: boolean;
}

const NavLink = (props: NavLinkProps) => {
  return (
      <Link
        as={RouterNavLink}
        px={2}
        py={1}
        rounded={"md"}
        _hover={{
          textDecoration: "none",
          bg: useColorModeValue("gray.200", "gray.900")
        }}
        _activeLink={{
          color: useColorModeValue("blue.500", "blue.200")
        }}
        onClick={() => props.onClose()}
        to={props.path}
      >
        {props.name}
      </Link>
  );
};


const CVLink = () => {
  return (
      <Link
        href="cv.pdf"
        px={2}
        py={1}
        rounded={"md"}
        _hover={{
          textDecoration: "none",
          bg: useColorModeValue("gray.200", "gray.900")
        }}
        _activeLink={{
          color: useColorModeValue("blue.500", "blue.200")
        }}
        >
          CV
        </Link>
  );
};

export default function TopNav() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box bg={useColorModeValue("white", "gray.700")} px={4} boxShadow={"lg"}>
        <Flex
          h={16}
          alignItems={"center"}
          justifyContent={"space-between"}
          w={["90%", "85%", "80%"]}
          maxW={1200}
          mx="auto"
        >
          <IconButton
            size={"md"}
            icon={isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
            aria-label={"Open Menu"}
            display={["inherit", "inherit", "none"]}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box>
              <Avatar
                as={Link}
                size={"sm"}
                href={"/"}
                src={UserIcon}
              />
            </Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {webLinks.map((link, index) => (
                <NavLink
                  key={index}
                  name={link.name}
                  path={link.path}
                  onClose={onClose}
                />
              ))}
              <CVLink></CVLink>
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>
            <ColorModeSwitcher justifySelf="flex-end" />
          </Flex>
        </Flex>

        {isOpen ? (
          <Box
            pb={4}
            w={["100%", "100%", "80%"]}
            maxW={1200}
            display={["inherit", "inherit", "none"]}
          >
            <Stack as={"nav"} spacing={4}>
              {mobileLinks.map((link, index) => (
                <NavLink
                  // index={index}
                  name={link.name}
                  path={link.path}
                  onClose={onClose}
                />
              ))}
              <CVLink></CVLink>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
