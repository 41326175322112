import * as React from "react";
import {
    Box,
    Image,
    Text,
    VStack
  } from "@chakra-ui/react";

import VDayImg from "assets/images/vday2024.png";

const VDay = () => {
  return (
    <VStack spacing={24}>
        <Image src={VDayImg} alt='Valentines day'  />
        <Box borderWidth='1px' borderRadius='lg' p='6' color='white' bg='white'>
            <Text fontSize='xlg' color='black'>
                
                In the city that never sleeps, where dreams take flight, <br/>
                I wander, my heart tethered to the western light. <br/>
                Across the miles, where the Golden Gate stands tall, <br/>
                My love, my heart, in San Francisco, you hold it all. <br/>
                <br/>
                But soon, the miles will vanish, like fog to the sun's embrace, <br/>
                As you journey eastward, to share my space. <br/>
                Our hearts, our lives, entwined in the city's endless maze, <br/>
                Together, forever, in New York's vibrant haze. <br/>
                <br/>
                From coast to coast, our love has flown, a testament so true, <br/>
                San Francisco to New York, I'll finally be with you. <br/>
                As March ends, and you step into this city's endless charm, <br/>
                We'll walk hand in hand, forever arm in arm. <br/>
                <br/>
                So here's to us, to love, to a future bright and new, <br/>
                To Bambi, and the paws that may join our crew. <br/>
                In the city of dreams, where all is possible, it seems, <br/>
                Together, my love, we'll live our shared dreams. <br/>
                <br/>
                ❤️ Daniel

            </Text>
        </Box>
    </VStack>
  )
};

export default VDay;
