import * as React from "react";
import { useState } from 'react';
import {
  Box,
  Text,
  Stack,
  Image,
  VStack,
  Container
} from "@chakra-ui/react";
import UserIcon from "assets/images/user_icon.png";
import AltUserIcon from "assets/images/other_user_icon.png";

import "style/style.css";

const Home = () => {

  const [isFlipped, setIsFlipped] = useState(false);
  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <VStack>
      <Box>
        <Image
          src={isFlipped ? AltUserIcon : UserIcon }
          alt='Avatar image of Daniel Hasegan' 
          boxSize='300px'
          objectFit='cover'
          borderRadius='25%'
          onClick={handleClick}
          />
      </Box>

      <Container  maxWidth='90%' >
        <Stack spacing={5} textAlign={'left'} >
          <Text fontSize='xl' as='b'>✌️ Hi,</Text>
          <Text fontSize='md'>
            I go by the name of Daniel Haşegan (pronounced as daan-e-el hush-eh-gun)!
            I live in New York City 🇺🇸 but I spent my childhood in Târgu Mureș, Romania 🇷🇴.
          </Text>

          <Text fontSize='xl' as='b'>🧠 Research Interest: Spatiotemporal Dynamics of Visual Perception
</Text>

          <Text fontSize='md'>
            My research curiosity centers around understanding the computational mechanisms that govern
            perception—mainly in the visual domain under the influence of higher-level processes like emotion.
            I am driven by the question:
              What are the dynamic processes that coordinate global brain computations during visual perception? 
          </Text>

          <Text fontSize='xl' as='b' >👨‍🔬 From Deep Learning to Neuroscience </Text>

          <Text fontSize='md'>
            I started as a computer scientist who once immersed myself in Deep Learning and Artificial Intelligence,
            and I am currently embarking on a voyage into Neuroscience.
            The start of my trip traces back to an interest in algorithms,
            leading to my pursuing a <Text as='b'> Computer Science degree at Jacobs University Bremen in Germany </Text>.
            Proficient in software engineering skills, I focused on crafting tools that emulate human intelligence.
            The collaboration with Dr. Michael Kohlhase involved the integration of mathematical knowledge graphs
            with queries rich in semantic context— an accomplishment that became my bachelor's thesis.
          </Text>
          <Text fontSize='md'>
            After I graduated in 2014, I joined the Deep Learning train.
            This road promised resolutions to intricate puzzles and hinted at the potential for 
            human-like intelligence emulation. San Francisco is where I started,
            joining <Text as='b'> Twitter's emergent Deep Learning team </Text>,
            <a href="https://cortex.twitter.com/"> <Text as='b'> Cortex </Text> </a>.
            Within this diverse team of engineers and scientists, I used Machine Learning techniques to instill
            “knowledge” into tweets, images, and videos. My integrations greatly impacted Twitter's fundamental products:
            Trends, Ads, Safety, and Recommendations.
          </Text>
          <Text fontSize='md'>
            An aspiration for a broader impact on society and health steered me towards <a href="https://braid.health"> <Text as='b'> Braid Health </Text> </a>.
            At this juncture, adding <Text as='b'> deep learning within radiological services </Text> resulted in advances in efficiency and enhanced
            precision in healthcare services.
            Collaborating closely with radiologists, I built classification models to render better diagnoses, transforming patient care.
            A revelation emerged — an inclination to delve into the very essence of human disorders, understanding their root cause.
            Deep Learning was not fulfilling the role of generating more knowledge and a better understanding of disorders, so I had to leave it behind. 
          </Text>
          <Text fontSize='md'>
            The pathway to academia unveiled itself, guiding me to <Text as='b'> Stanford's </Text>
              <a href="https://braindynamicslab.github.io/"> <Text as='b'> Brain Dynamics Lab </Text> </a>. 
            Under the mentorship of Dr. Manish Saggar, I started researching Mapper —
            an instrument derived from Topological Data Analysis to study the shape of high-dimensional datasets.
            Using patterns captured by functional Magnetic Resonance Imaging (fMRI), I ventured to find the most optimal
            usage of Mapper in extracting the dynamics of brain activity.
          </Text>
          <Text fontSize='md'>
            In 2022, I started formal academic training in Neuroscience and Physiology as part of a
              <a href="https://neuroscience.nyu.edu/"> <Text as='b'> {' '}Ph.D. program at NYU</Text> </a> . 
            Building upon my academic journey, I joined <a href="https://med.nyu.edu/helab/"> <Text as='b'> Dr. Biyu He's lab </Text> </a>,
            to continue investigating brain dynamics and perception while leveraging my robust engineering skills.
            In this pursuit, I am utilizing Electrocorticography (ECoG) techniques to delve into the intricacies of visual tasks,
            thus contributing to our understanding of cognitive processes.
            I am dedicated to unraveling the complex dynamic mechanisms of the human condition.
          </Text>
          <Text fontSize='md' textAlign={'center'}>
            Check out my publications <a href="/publications">👉 here 👈</a>. 
          </Text>
          <Text fontSize='md' textAlign={'center'}>
            Or explore further details of my industry past projects <a href="/experience">👉 here 👈</a>.
          </Text>

          <Text fontSize='2xl' as='b'>🤸‍♂️ </Text>

          <Text fontSize='md'>
            In my free time, I spend my time creating <a href="/music"> music </a>.
            Beyond that, my interests encompass snowboarding, rock climbing, immersing myself in sci-fi literature,
            engaging in friendly foosball matches, and gaming.</Text>

        </Stack>
      </Container>

    </VStack>
  );
};

export default Home;
