import * as React from "react";
import {
    Container,
    Grid,
    GridItem,
    Stack,
    Text,
  } from "@chakra-ui/react";
import siteConfig from "configs/site-config";

const Publications = () => {
  return (
    <Container  maxWidth='90%' >

        {/* The small header to link to GoogleScholar and save when updated */}
        <Stack spacing={2} textAlign={'right'} >
            <Text fontSize='sm' as='i'>Updated August 2024</Text>
            <Text fontSize='sm' as='i'>
            <a href='https://scholar.google.com/citations?hl=en&user=wJd-E18AAAAJ'>Google Scholar link</a>
            </Text>
        </Stack>

        {/* Actual content here */}
        <Stack spacing={10} textAlign={'left'} >

        <Stack spacing={4}>
          <Text fontSize='2xl' as='b'>Journal Publications</Text>
          {siteConfig.publications.pubs.map((pub) => 
                <Grid templateColumns='repeat(9, 1fr)' gap={6}>
                    <GridItem colSpan={1} >
                        <Text fontSize='md'>{pub.year}</Text>
                    </GridItem>
                    <GridItem colSpan={8} >
                        <Text fontSize='md'>{pub.text}</Text>
                    </GridItem>
                </Grid>
            )}
        </Stack>

        {siteConfig.publications.preprints.length > 0 ? (
            <Stack spacing={4}>
            <Text fontSize='2xl' as='b'>Preprints</Text>
            {siteConfig.publications.preprints.map((pub) => 
                    <Grid templateColumns='repeat(9, 1fr)' gap={6}>
                        <GridItem colSpan={1} >
                            <Text fontSize='md'>{pub.year}</Text>
                        </GridItem>
                        <GridItem colSpan={8} >
                            <Text fontSize='md'>{pub.text}</Text>
                        </GridItem>
                    </Grid>
                )}
            </Stack>
        ) : (<></>)}

        <Stack spacing={4}>
          <Text fontSize='2xl' as='b'>Posters</Text>
          {siteConfig.publications.posters.map((pub) => 
                <Grid templateColumns='repeat(9, 1fr)' gap={6}>
                    <GridItem colSpan={1} >
                        <Text fontSize='md'>{pub.year}</Text>
                    </GridItem>
                    <GridItem colSpan={8} >
                        <Text fontSize='md'>{pub.text}</Text>
                    </GridItem>
                </Grid>
            )}
        </Stack>

        </Stack>
    </Container>
  )
}

export default Publications;
