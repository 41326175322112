import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Experience from "./experience";
import Publications from "./publications";
import Books from "./books";
import Music from "./music";
import Home from "./home";
import VDay from "./vday";
import FreeSkateScores from "./skates";

const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/publications" element={<Publications />} />
      <Route path="/experience" element={<Experience />} />
      <Route path="/music" element={<Music />} />
      <Route path="/books" element={<Books />} />
      <Route path="/vday2024" element={<VDay />} />
      <Route path="/freeskatescores" element={<FreeSkateScores />} />
    </Routes>
  );
};

export default Navigation;
